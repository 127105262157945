import Vue from "vue";
import VueRouter from "vue-router";
import Store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: function () {
      return import(/* webpackChunkName: "home" */ "../views/Home.vue");
    },
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/fix",
    name: "Fix",
    component: function () {
      return import(/* webpackChunkName: "Fix" */ "../views/Fix.vue");
    },
    meta: {
      authRequired: true,
    },
  },
  {
    path: "*",
    component: function () {
      return import(/* webpackChunkName: "pageNotFound" */ "../views/404.vue");
    },
  },
  {
    path: "/login",
    name: "Login",
    title: "Kirish",
    component: function () {
      return import(/* webpackChunkName: "login" */ "../views/Login.vue");
    },
  },
];

const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? "hash" : "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  Store.dispatch("INIT_AUTH").then((user) => {
    if (to.matched.some((route) => route.meta.authRequired)) {
      if (user) {
        next();
      } else next("/login");
    } else {
      next();
    }
  });
});

export default router;
