export default {
  namespaced: true,
  state: {
    path: "users",
  },
  actions: {
    get({ dispatch, state, rootState }) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    check({ dispatch, state, rootState }, value) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/check/${value}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    checkNewAid({ dispatch, state, rootState }, value) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/check-new-aid/${value}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    add({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/add`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    block({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/block`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    unblock({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/unblock`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    password({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/set-password`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
  },
};
