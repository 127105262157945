import branches from "./branches";
import academicYears from "./academicYears";
import statistics from "./statistics";
import groups from "./groups";
import teachers from "./teachers";
import roles from "./roles";
import staffers from "./staffers";
import students from "./students";
import contracts from "./contracts";
import payments from "./payments";
import stafferTasks from "./stafferTasks";
import employeeRegistrations from "./employeeRegistrations";
import telegram from "./telegram";
import telegramParents from "./telegramParents";
import directorMyWeeks from "./directorMyWeeks";
import sciences from "./sciences";
import migration from "./migration";

export default {
  namespaced: true,
  modules: {
    branches,
    academicYears,
    staffers,
    statistics,
    groups,
    teachers,
    roles,
    students,
    contracts,
    payments,
    stafferTasks,
    employeeRegistrations,
    telegram,
    telegramParents,
    directorMyWeeks,
    sciences,
    migration,
  },
};
