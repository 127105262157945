export default {
  namespaced: true,
  state: {
    path: "aljabr/employee-registrations",
  },
  actions: {
    get({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.AlJabr.branch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/?${Object.entries(options)
            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
            .join("&")}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    list({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.AlJabr.branch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/list?${Object.entries(options)
            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
            .join("&")}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    check({ dispatch, state, rootState }, value) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/check/${value}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    add({ dispatch, state, rootState }, data) {
      data["branch"] = rootState.AlJabr.branch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    id({ dispatch, state, rootState }, value) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/id/${value}?short=${rootState.company.short.toLowerCase()}&branch=${rootState.AlJabr.branch._id}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    teachers({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.AlJabr.branch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/teachers?${Object.entries(options)
            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
            .join("&")}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    update({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/update`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
  },
};
