const serialize = (obj, prefix) => {
  let str = [];
  let p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      let k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push(v !== null && typeof v === "object" ? serialize(v, k) : encodeURIComponent(k) + "=" + encodeURIComponent(v));
    }
  }
  return str.join("&");
};

export default {
  namespaced: true,
  state: {
    path: "aljabr/telegram",
  },
  actions: {
    auth({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/auth`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    tasks({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/tasks?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    removeTask({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/remove-task`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    companies({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/companies?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    branches({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/branches?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    staffers({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/staffers?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    myTasks({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/my-tasks?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    myTask({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/my-task?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    setTaskStatus({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/set-task-status`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    taskRating({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/set-task-rating`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    payments({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/payments?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    groups({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/groups?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    sciences({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/sciences?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    addStudentRating({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add-student-rating`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    addStudentBehaviorAssessment({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add-student-behavior-assessment`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    visits({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/visits`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
  },
};
