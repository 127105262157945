import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VNumeric from "vuetify-numeric/vuetify-numeric.umd.min";
import socketio from "socket.io-client";
import VueSocketIO from "vue-socket.io";
import VueTelegram from "vue-tg";
import packageJSON from "./../package.json";

const serverURL = packageJSON.server[process.env.NODE_ENV] || "http://localhost:6412";

export const SocketInstance = socketio(serverURL, {
  extraHeaders: {
    "platform-app-name": packageJSON.name,
    "platform-app-version": packageJSON.version,
  },
  auth: (cb) => {
    cb({ token: sessionStorage.getItem("token") });
  },
  autoConnect: true,
});

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: SocketInstance,
  })
);

Vue.use(VueTelegram);

const options = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: false,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
};

Vue.config.productionTip = false;
Vue.use(Toast, options);
Vue.prototype.$eventHub = new Vue();
Vue.use(VNumeric);

new Vue({
  router,
  store,
  vuetify,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
