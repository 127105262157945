export default {
  namespaced: true,
  state: {
    path: "aljabr/groups",
  },
  actions: {
    get({ dispatch, state, rootState }) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/all-groups`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    list({ dispatch, state, rootState }, academicYearID) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/list/?branch=${rootState.AlJabr.branch._id}&academicYear=${academicYearID ? academicYearID : rootState.AlJabr.academicYear._id}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    last({ dispatch, state, rootState }) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/last/?branch=${rootState.AlJabr.branch._id}&academicYear=${rootState.AlJabr.academicYear._id}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    add({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        data["branch"] = rootState.AlJabr.branch._id;
        data["academicYear"] = rootState.AlJabr.academicYear._id;
        data["company"] = { short: rootState.company.short.toLowerCase() };
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/add`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    listWithStudents({ dispatch, state, rootState }, academicYearID) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/list-with-students/?branch=${rootState.AlJabr.branch._id}&academicYear=${academicYearID ? academicYearID : rootState.AlJabr.academicYear._id}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    save({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/save`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    id({ dispatch, state, rootState }, id) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/id/${id}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    removeStudent({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/remove-student`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
  },
};
