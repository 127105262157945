export default {
  namespaced: true,
  state: {
    path: "aljabr/statistics",
  },
  actions: {
    fixStudentsCount({ dispatch, state, rootState }) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/fix-students-count`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
  },
};
